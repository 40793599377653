import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `
    <hr>
      <footer class="pt-4 my-md-5 pt-md-5 container">
          <div class="row">
              <div class="col-12 col-md">
                  <a class="text-muted" target="_blank" href="https://twitter.com/fbellod86">
                      <img class="mb-2 rounded-circle" src="/assets/img/DonFrancos.png" alt="" width="50" height="50">
                  </a>
                  <small class="d-block mb-3 text-muted">&copy; 2020-2021</small>
              </div>
              <div class="col-6 col-md">
                  <h5>Redes Sociales</h5>
                  <ul class="list-unstyled text-small">
                      <li><a class="text-muted" target="_blank" href="https://twitter.com/fbellod86">Twitter</a></li>
                      <li><a class="text-muted" target="_blank" href="https://fbellod.medium.com/">Medium</a></li>
                      <li><a class="text-muted" target="_blank" href="https://github.com/fbellod1986">Github</a></li>
                      <li><a class="text-muted" target="_blank" href="mailto:franco.lauer@gmail.com">Email</a></li>
                  </ul>
              </div>
              <div class="col-6 col-md">
                  <h5>Grupos y Páginas</h5>
                  <ul class="list-unstyled text-small">
                      <li><a class="text-muted" target="_blank" href="https://www.facebook.com/angularboilerplate">Angular Boilerplate</a></li>
                      <li><a class="text-muted" target="_blank" href="https://www.facebook.com/groups/cicd.latinoamerica/">CI/CD Latinoamérica</a></li>
                      <li><a class="text-muted" target="_blank" href="https://www.facebook.com/groups/gitlablatam/">Gitlab Latinoamérica</a></li>
                  </ul>
              </div>
          </div>
      </footer>
  `
})
export class FooterComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
