import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  template: `
    <header>
        <div class="collapse bg-dark" id="navbarHeader">
            <div class="container">
                <div class="row">
                    <div class="col-sm-8 col-md-7 py-4">
                        <h4 class="text-white">Proyecto Boilerplate - Angular</h4>
                        <p class="text-muted">Proyecto armado y equipado con varias herramientas y dependencias que se usan actualmente, la idea es que puedas descargar esta plantilla y ocuparla en tus propios proyectos.
                        </p>
                    </div>
                    <div class="col-sm-4 offset-md-1 py-4">
                        <h4 class="text-white">Contacto</h4>
                        <ul class="list-unstyled">
                            <li><a href="https://twitter.com/fbellod86" target="_blank" class="text-white"><i
                      class="fa-brands fa-x-twitter mr-2"></i>Sígueme en X</a></li>
                            <li><a href="https://fbellod.medium.com/" target="_blank" class="text-white"><i
                      class="fab fa-medium-m mr-2"></i>Sígueme en Medium</a></li>
                            <li><a href="https://github.com/fbellod1986" target="_blank" class="text-white"><i
                      class="fab fa-github mr-2"></i>Sígueme en Github</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="navbar navbar-dark bg-dark shadow-sm">
            <div class="container d-flex justify-content-between">
                <a [routerLink]="['/']" class="navbar-brand d-flex align-items-center">
                    <i class="fab fa-angular tamano-icono-angular rotate mr-2"></i>
                    <strong>Franco Bello - Angular</strong>
                </a>
                <nav class="nav nav-masthead justify-content-center">
                    <a class="nav-link text-white" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/']">Home</a>
                    <a class="nav-link text-white" routerLinkActive="active" [routerLink]="['/prices']">Precios</a>
                </nav>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarHeader" aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
            </div>
        </div>
    </header>
  `,
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
