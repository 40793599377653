import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('@views/components/dashbaord/dashbaord.module').then(m => m.DashbaordModule) },
  { path: 'formulario-email/:txt/:id', loadChildren: () => import('@shared/components/formulario-email/formulario-email.module').then(m => m.FormularioEmailModule) },
  { path: 'bandeja-de-pago/:txt', loadChildren: () => import('@shared/components/bandeja-de-pago/bandeja-de-pago.module').then(m => m.BandejaDePagoModule) }, 
  { path: 'pay', loadChildren: () => import('@views/components/pay/pay.module').then(m => m.PayModule) },
  { path: 'respuesta-email/:txt', loadChildren: () => import('@shared/components/respuesta-email/respuesta-email.module').then(m => m.RespuestaEmailModule) },
  { path: 'prices', loadChildren: () => import('@views/components/prices/prices.module').then(m => m.PricesModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
